#btnCadastrarvendedor button{
  background: linear-gradient(180deg, #daa96e, #B08653 );
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-weight: bold;
  padding:10px;
  font-size: 13px;
  box-shadow: 0px 1px 3px #c2c2c2;
}
#btnCadastrarvendedor button:hover{
  background: linear-gradient(0deg, #dca863, #B08653 );
}

#btnCadastrarvendedor button:focus{
  outline:2px dotted #a5753b
}


@media (min-width: 361px) and (max-width: 376px) { 

  #btnCadastrarvendedor button {
    padding: 10px;
    margin-top: 10px;
    font-size: 12px;
  }
  
}


@media (min-width: 1025px) and (max-width: 1439px) { 

  #btnCadastrarvendedor button {
    padding: 10px;
    margin-top: 10px;
  }
  
}