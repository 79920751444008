#page-obrigado{
  background-color: #f3f3f3;
  font-family:'Roboto';
  display: flex;
  height: Calc(100vh - 90px);
}

#principal-obrigado{
  width: 100%;
  padding: 20px;
}