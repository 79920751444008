#containerclientes{
  overflow:auto;
  height:55%;
}

#cardcliente{
  background-color:#fff;
  border-radius:10px;
  font-size:12px;
  padding:10px;
  box-shadow: 1px 1px 4px #c2c2c2;
  width:200px;
  margin-bottom:10px;
  float: left;
}

.tabelaclientesanalisados{
  font-size:10px;
}

.btnReprovar {
  background-color: #ee451b;
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  width: 100%;
  padding:5px;
}
.btnReprovar:hover {
  background-color: rgb(197, 51, 21);
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  width: 100%;
  padding:5px;
}

.inputfiltroclientesap{
  background: #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  margin-bottom: 3px;
  display: flex;
  justify-content: flex-start;
}

.inputfiltroclientesap p{
  margin-bottom:0px;
  color:#fff;
  padding-top:2px;
  margin-right: 15px;
  margin-left: 10px;
}

#inputfiltroclientesap{
  padding-top: 0px;
  padding-left: 5px;
  border-radius: 5px;
  border-width: 0px;
  width: 100%;
}

#inputfiltroclientesap:focus{
  outline:2px dotted #B08653;
}

@media (min-width: 361px) and (max-width: 376px) { 

  .tabelaclientesanalisados{
    font-size:13px;
  }

  #containerclientes{
    overflow:auto;
    height:60%;
  }

  #cardcliente{
    margin-right:0px;
    width:230px;
  }

}

@media (min-width: 376px) and (max-width: 414px) { 

  .tabelaclientesanalisados{
    font-size:13px;
  }

  #containerclientes{
    overflow:auto;
    height:67%;
  }

  #cardcliente{
    margin-right:0px;
    width:270px;
  }

}


@media (min-width: 415px) and (max-width: 768px) { 

  #containerclientes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow:auto;
    height:80%
  }
  
  #containerclientes > #cardcliente {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
  }

}


@media (min-width: 769px) and (max-width: 1024px) { 

  #containerclientes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow:auto;
    height:80%;
  }
  
  #containerclientes > #cardcliente {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #containerclientes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow:auto;
  }
  
  #containerclientes > #cardcliente {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
  }

}



@media (min-width: 1440px) and (max-width: 2560px) { 
  .tabelaclientesanalisados{
    font-size:13px;
  }

  /* #containerclientes{
    overflow:auto;
    height:80%;
  }

  #cardcliente{
    margin-right:10px;
    width:300px;
  } */

  #containerclientes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 87%;
  }
  
  #containerclientes > #cardcliente {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
  }

  .colunarazao{
    height: 50px;
    display: flex;
    align-items:flex-start;
  }


  .actionbtn{
    height: 50px;
    display: flex;
    align-items:flex-end;
  }


}