#page-analise-clientes{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-analise-clientes{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
  overflow: hidden;
  height: 95%;
}

@media (max-width: 321px) { 

  #principal-analise-clientes{ 
    margin: 10px;
    padding: 10px;
  }
}

@media (min-width: 361px) and (max-width: 376px) { 

  #principal-analise-clientes{ 
    margin: 10px;
    padding: 10px;
    height: 95%;
  }
}


@media (min-width: 376px) and (max-width: 414px) { 

  #principal-analise-clientes{ 
    margin: 10px;
    padding: 10px;
    height: 95%;
  }
}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #principal-analise-clientes{ 
    margin: 15px;
    padding: 15px;
    height: 95%;
  }

}

@media (min-width: 1440px) and (max-width: 2560px) { 

  #principal-analise-clientes{ 
    margin: 15px;
    padding: 15px;
    height: 95%;
  }

}