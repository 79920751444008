#inputcreate p{
  color:#444;
  font-weight:bold;
  font-size: 14px;
  margin-bottom:5px;
  padding-top: 10px;
}
#inputcreate p span{
  margin-bottom:5px;
  padding-top: 5px;
  font-weight: normal;
  font-style: italic;
  font-size:12px;
}
#inputcreate input{
  background-color: #fff;
  width: 100%;
  font-size: 13px;
  border-radius: 10px;
  border-width: 0px;
  padding:10px;
  margin-bottom: 0px;
  box-shadow: 0px 1px 3px #c2c2c2;
}
#inputcreate input:focus{
  outline:2px dotted #B08653
}

#btnconsultar button{
  background: linear-gradient(180deg, #f3ba70, #B08653 );
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  width: 100%;
  padding:8px;
  margin-top: 20px;
}

#btnconsultarenable button{
  background-color: rgb(198, 198, 198);
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  width: 100%;
  padding:8px;
  margin-top: 20px;
  box-shadow: 0px 1px 3px #c2c2c2;
}


#btnCriarConta button{
  background: linear-gradient(180deg, #daa96e, #B08653 );
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-weight: bold;
  padding:15px;
  margin-top: 20px;
  box-shadow: 0px 1px 3px #c2c2c2;
}
#btnCriarConta button:hover{
  background: linear-gradient(0deg, #dca863, #B08653 );
}

#btnCriarConta button:focus{
  outline:2px dotted #a5753b
}

.aguarde{
  display: flex;
  align-items: center;
  justify-content: center;
  color:#222;
  height: 100px;
}

@media (min-width: 376px) and (max-width: 414px) { 



}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #btnCriarConta button {
    padding: 10px;
    margin-top: 10px;
  }

}