#page-produtos{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-produtos{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
}

@media (max-width: 321px) { 

  #principal-produtos{ 
    margin: 5px;
    padding: 5px;
  }
  #page-produtos #bag{
    display: none;
  }

}

@media (min-width: 361px) and (max-width: 376px) { 

  #principal-produtos{ 
    margin: 10px;
    padding: 10px;
  }
  #page-produtos #bag{
    display: none;
  }

}

@media (min-width: 376px) and (max-width: 414px) {
  
  #principal-produtos{
    margin: 10px;
    padding: 10px;
  }

  #page-produtos #bag{
    display: none;
  }

}


@media (min-width: 415px){

  #btncarrinhomobile{
    display: none;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) {

  #principal-produtos{
    margin: 15px;
    padding: 15px;
  }

}