#pedidosvendedorcontent {
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
  scroll-behavior:smooth;
}

.listaitens {
  background: #fff;
  padding: 5px 0px 5px 0px;
  text-align: left;
  font-size: 12px;
}

.listaitens:nth-child(even) {
  background-color: #f1f1f1;
}
.cinzaescuro {
  background-color: #c5c5c5;
  color:#222;
}

.cinzaescuro p {
  padding-top:10px;
  margin-bottom: 5px;
}

.cinza {
  background-color: #e0e0e0;
  color:#222;
}

/* The heart of the matter */
.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  padding:0px 0px;
}
.testimonial-group > .row > .col-3 {
  display: inline-block;
  max-width: 300px;
  padding: 0px;
  margin: 0px;
}
.col-3 h4 {
  font-size:14px;
  border-bottom: 1px solid #888;
  border-left: 1px solid #888;
  padding: 10px;
}
.col-3 p {
  padding: 5px;
  padding-left: 10px;
  border-left: 1px solid #888;
  font-size:12px;
}
.col-3 p::selection {
  background-color: #B08653;
}

/* Decorations */
.col-3 { background: #f1f1f1; color: #222; font-size: 16px; padding-bottom: 10px; padding-top: 8px; }
/* 
.col-3:nth-child(3n+1) { background: #f76; }
.col-3:nth-child(3n+2) { background: #f09; }
.col-3:nth-child(3n+3) { background: #f90; }  */

textarea{
  border:1px solid #999999;
  width:100%;
  margin:5px 0;
  padding:3px;
}

#filtros{
  margin-bottom:20px;
}

.inputfiltropedidos-vd{
  background: #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
}

.inputfiltropedidos-vd p{
  margin-bottom:0px;
  color:#fff;
  padding-top:2px;
  margin-right: 15px;
  margin-left: 10px;
}

#inputfiltropedidos-vd{
  padding-top: 0px;
  padding-left: 5px;
  border-radius: 5px;
  border-width: 0px;
  padding-left: 10px;
  width: 100%;
}

#inputfiltropedidos-vd:focus{
  outline:2px dotted #B08653;
}


.alinhar {
  display: flex;
  justify-content: flex-end;
}

.btnCollapse{
  background-color: #d3d3d3;
  color:#fff;
  margin-top: 5px;
  border:none !important;
}

.btnCollapse:focus, .btnCollapse:not(:disabled){
  outline: none!important;
}


#view-vd{
  background-color: #d3d3d3;
  color:#B08653;
  width: 130px;
  margin-bottom: 5px;
  margin-left: 10px;
}

#valor-vd{
  background-color: #e0e0e0;
  color:#555;
  width: 100px;
  margin-bottom: 5px;
  user-select: none;
  margin-left: 10px;
}

#aprove-vd{
  background-color: #e0e0e0;
  color:#3c8b0f;
  margin-bottom: 5px;
  user-select: none;
  padding-left: 10px;
}

#reprove-vd{
  background-color: #e0e0e0;
  color:rgb(255, 153, 0);
  font-weight: bold;
  margin-bottom: 5px;
  user-select: none;
  padding-left: 10px;
}

#remove-vd{
  color: #ac2a13;
  background-color: #d3d3d3;
  margin-bottom: 5px;
  user-select: none;
  margin-right: 0px;
  margin-left: 10px;
}

#valor-vd{
  background-color: #e0e0e0;
  color: #555;
  margin-right: 10px;
  margin-bottom: 5px;
  user-select: none;
}

#empresa-vd{
  background-color: #e0e0e0;
  color: #555;
  margin-right: 0px;
  margin-bottom: 5px;
  user-select: none;
}

#showorder-vd{
  color:#B08653;
  background-color: #d3d3d3;
  margin-right: 10px;
  margin-bottom: 5px;
  user-select: none;
}

.alinharpedidocliente {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}

#divpararenderizar-vd{
  font-size: 13px;
}

@media (min-width: 769px) and (max-width: 1025px) { 

  #showorder-vd {
    width: 50px;
  }
  #showorder-vd p{
    margin-bottom:0px;
  }
  #showorder-vd span{
    display: none;
  }
  #showorder-vd i{
    display: none;
  }
  #empresa-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-vd {
    margin-right: 0px;
  }
  #valor-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-vd i{
    display: none;
  }
  #aprove-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-vd i{
    display: none;
  }
  #view-vd p{
    margin-bottom:0px;
  }

  #view-vd i{
    margin-right: 10px;
  }
  #remove-vd p{
    margin-bottom:0px;
  }
  #remove-vd i{
    display: none;
  }
  #remove-vd span{
    display: none;
  }
  #divpararenderizar-vd p{
    font-size:10px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 
  #showorder-vd p{
    margin-bottom:0px;
  }
  #view-vd p{
    margin-bottom:0px;
  }
  #empresa-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #valor-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-vd p{
    margin-bottom:0px;
  }
  #reprove-vd p{
    margin-bottom:0px;
  }

  #divpararenderizar-vd p{
    font-size:12px;
  }
  
}

@media (min-width: 1440px) and (max-width: 2560px) { 

  #showorder-vd p{
    margin-bottom:0px;
  }
  #empresa-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-vd {
    margin-right: 0px;
  }
  #valor-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-vd p{
    font-size:13px;
    margin-bottom:0px;
  }
  #view-vd p{
    margin-bottom:0px;
  }
  #view-vd i{
    margin-right: 10px;
  }
  #remove-vd p{
    margin-bottom:0px;
  }
  #divpararenderizar-vd p{
    font-size:13px;
  }

}