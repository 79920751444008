.wrapper {
  #selecionevendedor{
    font-size:20px;
  }
  .item__close__btn__wrapper {
    display: flex;
    position: relative;
    top: -5px;
    justify-content: flex-end;
  }
  .item__close__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B08653;
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
  }
  .item__close__btn:hover {
    background: #fff;
    color:#B08653
  }
  *:hover ~ #container {
    color: red;
    background-color: #B08653;
  }
  .bag__item {
    display: flex;
    align-items: center;
  }
  .bag__empty {
    font-size: 26px;
    margin-top: 46px;
    text-align: center;
  }
  .small__btn {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 12px;
    color: #fff;
    line-height: 8px;
    text-align: center;
    background: #B08653;
    cursor: pointer;
  }
  .small__btn:hover {
    background: #fff;
    color:#926e41;
    user-select: none;
  }
  .bag__item span {
    line-height: 36px;
    display: block;
    font-size: 15px;
    color:#fff;
    font-weight: bold;
  }
  .bag__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 40px;
  }
  .bag__total {
    padding: 16px 0;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
  }
  .checkout__btn {
    background: #B08653;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 15px;
    width: 80%;
  }
  .checkout__btn:hover {
    background: #926e41;
  }
  .bag__operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
  }
  .bag__item__container {
    background-color: #222;
    color:#fff;
    font-weight: bold;
    border-top: 1px solid #555;
    border-bottom: 1px solid #555;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    margin:10px;
  }
  .items__container {
    height: 60vh;
    overflow-y: scroll;
  }
  .items__container::-webkit-scrollbar {
    width: 5px;
    border-radius: 80px;
  }
  .items__container::-webkit-scrollbar-thumb {
    background: #B08653;
  }
  .items__container::-webkit-scrollbar-thumb:hover {
    background: #B08653;
  }
  .slide-enter {
    margin-right: -400px;
  }
  .slide-enter-active {
    margin-right: 0;
    transition: 0.3s ease-in;
  }
  .slide-exit {
    margin-right: 0;
  }
  .slide-exit-active {
    margin-right: -400px;
    transition: 0.3s ease-out;
  }
}
