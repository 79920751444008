#header{
  background-color: #222;
  padding: 10px;
  border-bottom: 1px solid #333;
}

#title strong {
  font: 700 19px 'Poppins', sans-serif;
  color: #fff;
  margin-left: 15px;
  float: left;
  line-height:25px;
  margin-top: 5px;
}

#logommv img{
  width:60px;
  float:left;
  cursor: pointer;
}

#logout{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#logout p{
  color:#B08653;
  font-size:20px;
  padding-top: 10px;
}