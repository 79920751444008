.grid-wrapper{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  height: 98%;
  overflow: auto;
  align-items: center;
  flex-direction: row;
  scroll-behavior:smooth;
  margin: 0px auto;
  padding-bottom: 30px;
}

.grid-wrapper .child{
  background-color:#eee;
  cursor: pointer;
  width:200px;
  height:100px;
  position: relative;
  border-radius: 10px;
  color: #444;
}

.grid-wrapper .child:hover{
  background-color:#bebebe;
  cursor: pointer;
  width:200px;
  height:100px;
  position: relative;
  border-radius: 10px;
  color: #333;
}

.grid-wrapper .child h2{
  font: 700 12px 'Poppins', sans-serif;
  text-align: center;
  text-transform: uppercase;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 376px) and (max-width: 414px) { 
  .grid-wrapper .child{
    width:45%;
    height:10vh;
  }
  .grid-wrapper .child:hover{
    width:45%;
    height:10vh;
  }



}


@media (min-width: 1025px) and (max-width: 1439px) {

  .grid-wrapper .child{
    width:140px;
  }  
  .grid-wrapper .child:hover{
    width:140px;
  }
  .grid-wrapper .child h2{
    font: 700 11px 'Poppins', sans-serif;
  }
}