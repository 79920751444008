#page-pedidos{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-pedidos{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
}


@media (max-width: 321px) { 

  #principal-pedidos{
    margin: 10px;
    padding: 10px;
  }

}

@media (min-width: 321px) and (max-width: 376px) { 

  #principal-pedidos{
    margin: 5px;
    padding: 5px;
  }

}

@media (min-width: 376px) and (max-width: 414px) { 

  #principal-pedidos{
    margin: 10px;
    padding: 10px;
  }

}

@media (min-width: 415px) and (max-width: 769px) { 
  #principal-pedidos{
    margin: 10px;
    padding: 10px;
  }

}

@media (min-width: 769px) and (max-width: 1025px) { 
  #principal-pedidos{
    margin: 10px;
    padding: 10px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #principal-pedidos{
    margin: 15px;
    padding: 15px;
  }
}

