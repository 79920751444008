#pedidoscontent {
  height: 95%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 200px;
  scroll-behavior:smooth;
}

.listaitens {
  background: #fff;
  padding: 5px 0px 5px 0px;
  text-align: left;
  font-size: 12px;
}

.listaitens:nth-child(even) {
  background-color: #f1f1f1;
}
.cinzaescuro {
  background-color: #c5c5c5;
  color:#222;
}
.cinzaescuro p {
  padding-top:10px;
  margin-bottom: 5px;
}
.cinza {
  background-color: #e0e0e0;
  color:#222;
}

.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  padding:0px 0px;
}
.testimonial-group > .row > .col-3 {
  display: inline-block;
  max-width: 300px;
  padding: 0px;
  margin: 0px;
}
.col-3 h4 {
  font-size:14px;
  border-bottom: 1px solid #888;
  border-left: 1px solid #888;
  padding: 10px;
}
.col-3 p {
  padding: 5px;
  padding-left: 10px;
  border-left: 1px solid #888;
  font-size:12px;
}
.col-3 p::selection {
  background-color: #B08653;
}

/* Decorations */
.col-3 { background: #f1f1f1; color: #222; font-size: 16px; padding-bottom: 10px; padding-top: 8px; }
/* 
.col-3:nth-child(3n+1) { background: #f76; }
.col-3:nth-child(3n+2) { background: #f09; }
.col-3:nth-child(3n+3) { background: #f90; }  */

textarea{
  border:1px solid #999999;
  width:100%;
  margin:5px 0;
  padding:3px;
}

#pedidocontainerfluid{
  border:1px solid #999999;
  margin-bottom: 20px;
  border-radius: 5px;
}

#filtros{
  margin-bottom:20px;
}

.inputfiltropedidos{
  background: #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
}

.inputfiltropedidos p{
  margin-bottom:0px;
  color:#fff;
  padding-top:2px;
  margin-right: 15px;
  margin-left: 10px;
}

#inputfiltropedidos{
  padding-top: 0px;
  padding-left: 5px;
  border-radius: 5px;
  border-width: 0px;
  width: 100%;
}

#inputfiltropedidos:focus{
  outline:2px dotted #B08653;
}

.dropdownfilters{
  background-color: #d3d3d3 !important;
  border-color: #d3d3d3 !important;
  padding: 0px !important;
  width: 100%;
}
.dropdownfilters p{
  font-size:15px;
  padding:3px;
  margin-bottom:0px;
}

.dropdownfilters::after {
  display:none !important;
}

.alinhar {
  display: flex;
  justify-content: flex-end;
}

.btnCollapse{
  background-color: #d3d3d3;
  color:#fff;
  margin-top: 5px;
  border:none !important;
}

.btnCollapse:focus, .btnCollapse:not(:disabled){
  outline: none!important;
}

#view-su{
  background-color: #d3d3d3;
  color:#B08653;
  width: 80px;
  user-select: none;
  margin-bottom: 5px;
  width: 130px;
}

#vendedor-su{
  background-color: #e0e0e0;
  color:#555;
  min-width: 120px;
  user-select: none;
  margin-bottom: 5px;
  margin-right: 10px;
}

#valor-su{
  background-color: #e0e0e0;
  color:#555;
  width: 100px;
  user-select: none;
  margin-bottom: 5px;
  margin-right: 10px;
}

#aprove-su{
  background-color: #e0e0e0;
  color:#3c8b0f;
  margin-bottom: 5px;
  user-select: none;
  padding-left: 10px;
}

#reprove-su{
  background-color: #e0e0e0;
  color:rgb(252, 151, 0);
  font-weight: bold;
  margin-bottom: 5px;
  user-select: none;
  padding-left: 10px;
}

#remove-su{
  color: #B08653;
  background-color: #d3d3d3;
  margin-bottom: 5px;
  user-select: none;
  margin-right: 0px;
  margin-left: 10px;
}

#remove-su i{
  color: #ca0000;
}

#print-su{
  color: #B08653;
  background-color: #d3d3d3;
  margin-bottom: 5px;
  margin-right: 10px;
}
#print-su  p {
  margin-bottom: 0px;
}
#print-su  p i{
  color: #3c8b0f;
}

#empresa-su{
  background-color: #e0e0e0;
  color: #555;
  margin-right: 0px;
  margin-bottom: 5px;
  user-select: none;
  /* max-width:30ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:nowrap; */
}

#showorder-su{
  color:#B08653;
  background-color: #d3d3d3;
  margin-right: 10px;
  margin-bottom: 5px;
  user-select: none;
}

.alinharpedidocliente {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
}

#divpararenderizar{
  font-size: 13px;
}


@media (max-width: 321px) { 

  #pedidoscontent {
    height: 53%;
  }

  .inputfiltropedidos{
    background: #d3d3d3;
    border-radius: 5px;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3px;
    width: 100%;
  }
  
  #inputfiltropedidos{
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    border-width: 0px;
    width: 100%;
  }
  
  #inputfiltropedidos:focus{
    outline:2px dotted #B08653;
  }

  .alinharpedidocliente{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    user-select: none;
  }

  #showorder-su{
    font-size:12px;
    padding: 2px 5px;
  }

  #empresa-su{
    font-size:12px
  }

  #view-su{
    display:none;
  }

  #vendedor-su{
    display:none;
  }

  #aprove-su{
    display:none;
  }

  #reprove-su{
    display:none;
  }

  #remove-su{
    display:none;
  }

  #valor-su{
    display:none;
  }

  #divpararenderizar{
    font-size: 11px;
  }
  

}

@media (min-width: 361px) and (max-width: 376px) { 

  #pedidoscontent {
    height: 65%;
  }

  .inputfiltropedidos{
    background: #d3d3d3;
    border-radius: 5px;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3px;
    width: 100%;
  }
  #showorder-su p{
    margin-bottom:0px;
    font-size:14px;
  }
  #showorder-su span{
    display:none;
  }
  #showorder-su {
    width: 70px;
  }
  #divpararenderizar-su{
    font-size: 10px;
  }
  #valor-su {
    width:80px;
    font-size: 14px;
  }
  #valor-su p{
    margin-bottom:0px;
  }
  #empresa-su {
    display: none;
  }
  #reprove-su {
    display: none;
  }
  #aprove-su {
    display: none;
  }
  #view-su{
    display: none;
  }
  #vendedor-su{
    background-color: #e0e0e0;
    font-size: 14px;
    color:#555;
    width: 100px;
    user-select: none;
  }
  #remove-su p{
    margin-bottom:0px;
  }

}

@media (min-width: 376px) and (max-width: 414px) { 

  #pedidoscontent {
    height: 63%;
  }
  .inputfiltropedidos{
    background: #d3d3d3;
    border-radius: 5px;
    padding: 3px;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3px;
    width: 100%;
  }
  #showorder-su p{
    margin-bottom:0px;
    font-size:14px;
  }
  #showorder-su span{
    display:none;
  }
  #showorder-su {
    width: 70px;
  }
  #divpararenderizar-su{
    font-size: 10px;
  }
  #valor-su {
    width:80px;
    font-size: 14px;
  }
  #valor-su p{
    margin-bottom:0px;
  }  
  #empresa-su {
    display: none;
  }
  #reprove-su {
    display: none;
  }
  #aprove-su {
    display: none;
  }
  #view-su{
    display: none;
  }
  #vendedor-su{
    background-color: #e0e0e0;
    font-size: 14px;
    color:#555;
    width: 100px;
    user-select: none;
  }
  #remove-su p{
    margin-bottom:0px;
  }

}

@media (min-width: 415px) and (max-width: 769px) { 

}

@media (min-width: 769px) and (max-width: 1025px) { 

  #showorder-su {
    width: 50px;
  }
  #showorder-su p{
    margin-bottom:0px;
  }
  #showorder-su span{
    display: none;
  }
  #showorder-su i{
    display: none;
  }
  #empresa-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-su {
    margin-right: 5px;
  }
  #valor-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-su i{
    display: none;
  }
  #aprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-su i{
    display: none;
  }
  #view-su p{
    margin-bottom:0px;
  }
  #view-su span{
    display: none;
  }
  #view-su i{
    display: none;
  }
  #remove-su p{
    margin-bottom:0px;
  }
  #remove-su i{
    color: #ca0000;
  }
  #remove-su span{
    display: none;
  }
  #divpararenderizar p{
    font-size:10px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #pedidoscontent {
    height: 87%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
    scroll-behavior:smooth;
  }

  #showorder-su p{
    margin-bottom:0px;
  }
  #empresa-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-su p{
    font-size:13px;
    margin-bottom:0px; 
  }
  #valor-su p{
    font-size:13px;
    margin-bottom:0px; 
  }
  #reprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-su i{
    display: none;
  }
  #aprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-su i{
    display: none;
  }
  #view-su p {
    margin-bottom:0px; 
  }
  #remove-su span{
    display: none;
  }
  #remove-su p{
    margin-bottom:0px;
  }


}

@media (min-width: 1440px) and (max-width: 2560px) { 
  
  #pedidoscontent {
    height: 87%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
    scroll-behavior:smooth;
  }

  #showorder-su p{
    margin-bottom:0px;
  }
  #empresa-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #vendedor-su {
    margin-right: 10px;
  }
  #valor-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #reprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #aprove-su p{
    font-size:13px;
    margin-bottom:0px;
  }
  #view-su p{
    margin-bottom:0px;
  }
  #view-su i{
    margin-right: 10px;
  }
  #remove-su p{
    margin-bottom:0px;
  }
  #remove-su span{
    display: none;
  }
  #divpararenderizar p{
    font-size:13px;
  }

}