@page {
  size: A4;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
}

@media print{

  body, html {
    width: 21cm !important;
  }
  .print-preview {
    width: 21cm;
    padding-top: 1cm;
    padding-bottom: 1cm;
  }
  .header-cliente-print-top{
    width: 21cm;
    border-top: 1px dashed #444;
    background-color:#fff
  }

  .header-cliente-print{
    width: 21cm;
    border-top: 1px dashed #444;
    border-bottom: 1px dashed #444;
    background-color:#fff;
  }

  .header-cliente-print-btt{
    width: 21cm;
    border-bottom: 1px dashed #444;
    background-color:#fff
  }

  .pedido-cliente-print{
    width: 3.5cm;
    font-size:9px;
    padding-left: 1cm;
    /* background-color: rgb(0, 42, 255); */
  }

  .data-cliente-print{
    width: 2.5cm;
    padding-left: 0.5cm;
    font-size:9px;
    /* background-color: rgb(255, 106, 0); */
  }

  .cliente-cliente-print{
    width: 7.5cm;
    font-size:9px;
    text-align: center;
    /* background-color: rgb(221, 0, 255); */
  }

  .cnpj-cliente-print{
    width: 4cm;
    font-size:9px;
    /* background-color: rgb(255, 0, 85); */
  }

  .status-cliente-print{
    width: 3.5cm;
    font-size:9px;
    /* background-color: rgb(0, 255, 191); */
  }

  .fonte-padrao-print {
    font-size: 8px;
  }

  .pd-first{
    padding-left: 1cm;
    width: 7cm;
  }

  .pd-sec{
    padding-left: 1cm;
    width: 7cm;
  }

  .pd-three{
    padding-left: 1cm;
    width: 7cm;
  }




  .itens-pedido-print {
    width: 21cm;
    padding-right: 1cm;
    padding-left: 1cm;
    font-size: 8px;
    /* background-color: #f09; */
  }
  .header-line-print{
    width: 21cm;
    background-color: #fff;
    border-bottom: 1px dashed #444;
  }
  .codigo-header-print{
    width: 3cm;
    padding-left: 1cm;
    /* background-color: rgb(0, 42, 255); */
  }
  .descricao-header-print{
    width: 10cm;
    /* background-color: rgb(255, 0, 68); */
  }
  .valorunidade-header-print{
    width: 3cm;
    padding-left: 1cm;
    text-align: left;
    /* background-color: rgb(185, 101, 48); */
  }
  .quantidade-header-print{
    width: 3cm;
    text-align: center;
    /* background-color: rgb(218, 1, 197); */
  }
  .total-header-print{
    width: 3cm;
    /* background-color: rgb(136, 255, 0); */
  }

  .lista-de-itens-print {
    background: #fff;

    padding: 0.2cm 0cm 0.2cm 0cm;
    text-align: left;
    font-size: 9px;
  }
  
  .lista-de-itens-print:nth-child(even) {
    background-color: #f1f1f1;
  }

}