#page-cadastro{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-cadastro{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
}

@media (max-width: 320px) { 
  #principal-cadastro{ 
    margin: 10px;
    padding: 10px;
    overflow: scroll;
    padding-bottom: 10vh;
    height:95%;
  }
}

@media (min-width: 321px) and (max-width: 360px) { 
  #principal-cadastro{ 
    margin: 10px;
    padding: 10px;
    overflow: scroll;
    padding-bottom: 10vh;
    height:95%;
  }
}

@media (min-width: 361px) and (max-width: 376px) { 
  #principal-cadastro{ 
    margin: 10px;
    padding: 10px;
    overflow: scroll;
    padding-bottom: 10vh;
    height:95%;
  }
}

@media (min-width: 376px) and (max-width: 414px) { 
  
  #principal-cadastro{
    margin: 10px;
    padding: 10px;
    padding-bottom: 0px;
    overflow: scroll;
    height:95%;
    padding-bottom: 20vh;
  }
  
}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #principal-cadastro{ 
    margin: 15px;
    padding: 15px;
    height:95%;
    overflow: scroll;
  }
}