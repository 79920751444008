.tabelavendedoresanalise{
  font-size:13px;
}

#cardvendedor{
  border-radius:10px;
  font-size:12px;
  padding-top: 10px;
  box-shadow: 1px 1px 4px #c2c2c2;
  width:200px;
  margin-bottom:10px;
  float: left;
}

.btnAprovarAnaliseVendedor {
  background-color: rgb(153, 185, 98);
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  width:100%;
  padding: 5px;
}


@media (min-width: 415px) and (max-width: 768px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height: 200px;
  }

}


@media (min-width: 769px) and (max-width: 1024px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height: 200px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height: 200px;
  }

}

@media (min-width: 1440px) and (max-width: 2560px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height: 200px;
  }

}