#page-home{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-home{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px 20px 200px 20px;
  display: flex;
  justify-content:space-around; 
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior:smooth;  
}

#principal-banner{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  justify-content:center; 
  background-color: #232323; 
}

#page-home-analise{
  display: flex;
  justify-content:center; 
  align-items: center;
  text-align: center;
  flex-direction:column;
}

.containerboxhome{
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 0px;
}

.boxhome{
  background-color: rgb(235, 235, 235);
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  width:300px;
  height: 200px;
  padding: 30px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;
  cursor: pointer;
}

.boxhome h1{
  color:#B08653;
}

.banner{
  display: flex;
  justify-content:center;
  align-items:center;
}



@media (max-width: 321px) { 

  #principal-home {
    margin: 10px;
    padding: 0px;
  }

  #principal-banner {
    margin: 10px;
    padding: 0px;
  }

  .containerboxhome{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 0;
    overflow-y: scroll;
    height: 78vh;
  }

  .boxhome{
    width:100%;
    padding: 10px;
    margin: 20px;
    height: 25vh;
  }

}


@media (min-width: 321px) and (max-width: 376px) { 

  #principal-home {
    margin: 10px;
    padding: 0px;
  }
  #principal-banner {
    margin: 10px;
    padding: 0px;
  }


  .containerboxhome{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 0;
    overflow-y: scroll;
    height: 78vh;
    margin-top:10px;
    padding-bottom: 50px;
  }

  .boxhome{
    width:100%;
    padding: 10px;
    margin: 20px;
    height: 25vh;
  }

}

@media (min-width: 376px) and (max-width: 414px) { 
  
  #principal-home {
    margin: 20px;
    padding: 0px;
  }
  #principal-banner {
    margin: 20px;
    padding: 0px;
  }  
  .containerboxhome{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 0;
    overflow-y: scroll;
    height: 78vh;
    margin-top:10px;
    padding-bottom: 50px;
  }
  .boxhome {
    width: 100%;
    padding: 0px;
    margin: 10px 20px;
    height: 20vh;
  }

}

@media (min-width: 415px) and (max-width: 769px) { 

}

@media (min-width: 769px) and (max-width: 1025px) { 
  
  .boxhome {
    background-color: rgb(235, 235, 235);
    border-radius: 20px;
    box-shadow: 1px 1px 5px #c2c2c2;
    width: 260px;
    height: 200px;
    padding: 30px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #principal-home{
    margin: 20px;
    padding: 10px;
    padding-bottom: 0px;
  }
  .containerboxhome{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .boxhome {
    width: 350px;
    height: 200px;
    padding: 20px;
    margin: 15px;
  }


}