#filtrinhos{
  margin-bottom: 15px;
  padding-bottom:10px;
  box-shadow: 0px 15px 10px -15px rgb(156, 156, 156); 
}

.product__wrapper {
  height: 92%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
  scroll-behavior:smooth;
}

.card__container {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.inputfiltroprodutos{
  background: #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  margin:3px;
  display: flex;
  justify-content: flex-start;
}

.inputfiltroprodutos p{
  margin-bottom:0px;
  color:#fff;
  padding-top:2px;
  margin-right: 15px;
  margin-left: 10px;
}

#inputfiltroprodutos{
  padding-top: 0px;
  border-radius: 5px;
  border-width: 0px;
  width: 100%;
  padding-left: 10px;
}

#inputfiltroprodutos:focus{
  outline:2px dotted #B08653;
}


.btnLoadMore{
  height: 55px;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(217deg, #F9C785, #B08653 );
  color: #fff;
  padding: 10px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  border-radius: 28px;
  transition: filter 0.2s;

  width:30%;
  margin:30px auto;

}

@media (max-width: 321px) { 
  .product__wrapper {
    height: 77%;
  }
}

@media (min-width: 361px) and (max-width: 376px) { 
  .product__wrapper {
    height: 80%;
  }
}

@media (min-width: 376px) and (max-width: 414px) { 

  .product__wrapper {
    height: 83%;
  }

}

@media (min-width: 769px) and (max-width: 1025px) { 
  .product__wrapper {
    height: 87%;
  }
}
