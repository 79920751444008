#page-carrinho{
  background-color: #f3f3f3;
  font-family:'Poppins';
  height: Calc(100vh - 90px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

#principal-carrinho{
  border-radius: 20px;  
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
}

@media (max-width: 321px) { 
  #principal-carrinho{ 
    display: none;
  }

  .tamanhodocarrinho{
    width: 250px
  }
}

@media (min-width: 361px) and (max-width: 376px) { 
  #principal-carrinho{ 
    display: none;
  }

  .tamanhodocarrinho{
    width: 295px
  }
}

@media (min-width: 377px) and (max-width: 415px) { 
  #principal-carrinho{ 
    display: none;
  }

  .tamanhodocarrinho{
    width: 334px
  }
}
