#containervendedor{
  padding-bottom: 30px;
}

.tabelavendedoressanalisados{
  font-size:13px;
}

@media (min-width: 361px) and (max-width: 376px) { 

  #containervendedor{
    padding-bottom: 30px;
    height:100%;
    overflow: auto;
  }

  #cardvendedor{
    background-color:#fff;
    border-radius:10px;
    font-size:12px;
    padding:10px;
    box-shadow: 1px 1px 4px #c2c2c2;
    width:245px;
    margin-bottom:10px;
    float: left;
    margin-right: 0px;
  }

  .btnReprovar {
    background-color: #ee451b;
    border-radius: 10px;
    border-width: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    padding: 5px;
}

}


@media (min-width: 376px) and (max-width: 414px) { 

  #containervendedor{
    padding-bottom: 30px;
    height:100%;
    overflow: auto;
  }

  #cardvendedor{
    background-color:#fff;
    border-radius:10px;
    font-size:12px;
    padding:10px;
    box-shadow: 1px 1px 4px #c2c2c2;
    width:285px;
    margin-bottom:10px;
    float: left;
    margin-right: 0px;
  }

  .btnReprovar {
    background-color: #ee451b;
    border-radius: 10px;
    border-width: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    padding: 5px;
}

}

@media (min-width: 415px) and (max-width: 768px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height: 300px;
  }

}


@media (min-width: 769px) and (max-width: 1024px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    min-height: 280px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 90%;
    overflow: scroll;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    min-height: 280px;
  }

}


@media (min-width: 1440px) and (max-width: 2560px) {

  #containervendedor {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 90%;
    overflow: scroll;
  }
  
  #containervendedor > #cardvendedor {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    min-height: 280px;
  }


  
}