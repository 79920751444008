#page-cadastrodevendedores{
  background-color: #f3f3f3;
  font-family:'Roboto';
  display: flex;
  height: Calc(100vh - 90px);
}

#principal-cadastrodevendedores{
  border-radius: 20px;
  box-shadow: 1px 1px 5px #c2c2c2;
  margin: 30px;
  width:100%;
  padding: 20px;
  padding-bottom: 0px;
}

@media (max-width: 321px) { 

  #principal-cadastrodevendedores{ 
    margin: 10px;
    padding: 10px;
    overflow: auto;
    padding-bottom: 50px;
  }
  #principal-cadastrodevendedores h5{
    font-size: 18px;
  } 

}

@media (min-width: 361px) and (max-width: 376px) { 

  #principal-cadastrodevendedores{ 
    margin: 10px;
    padding: 10px;
    overflow: auto;
    padding-bottom: 50px;
  }
  #principal-cadastrodevendedores h5{
    font-size: 18px;
  } 

}

@media (min-width: 376px) and (max-width: 414px) { 

  #principal-cadastrodevendedores{ 
    margin: 10px;
    padding: 10px;
    overflow: auto;
    padding-bottom: 50px;
  }
  #principal-cadastrodevendedores h5{
    font-size: 18px;
  } 

}


@media (min-width: 1025px) and (max-width: 1439px) { 
  
  #principal-cadastrodevendedores{ 
    margin: 15px;
    padding: 15px;
  }
}