#header{
  background-color: #222;
  padding: 10px;
  border-bottom: 1px solid #333;
}

#title strong {
  font: 700 18px 'Poppins', sans-serif;
  color: #fff;
  margin-left: 15px;
  float: left;
  line-height:25px;
  margin-top: 5px;
}

#logommv img{
  width:60px;
  float:left;
  cursor: pointer;
}

#logout{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#logout p{
  color:#B08653;
  font-size:20px;
  padding-top: 10px;
}


@media (max-width: 321px) { 

  #hello{
    display: none;
  }
  #sair{
    display: none;
  }

}

@media (min-width: 321px) and (max-width: 376px) { 

  #hello{
    display: none;
  }
  #sair{
    display: none;
  }

}

@media (min-width: 376px) and (max-width: 414px) { 
  #hello{
    display: none;
  }
  #sair{
    display: none;
  }

}

@media (min-width: 415px) and (max-width: 769px) { 

}

@media (min-width: 769px) and (max-width: 1025px) { 

}
