.card__wrapper {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .card {
    padding-top: 10px;
    display: flex;
    border: 1px solid #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    border-radius: 10px;
  }

  .card:hover .add__btn {
    background: #92d24e;
    color: #fff;
  }
  .card:hover {
    border: 1px dashed rgb(196, 196, 196);
  }

  img {
    border-radius: 5px 5px 0 0;
  }
  p::before {
    content: '';
    display: block;
    border-top: 3px solid #B08653;
    height: 10px;
    width: 20px;
    text-align: center;
  }
  p {
    text-align: center;
    font-weight: bold;
  }

  .imgproduct{
    width: 150px;
    height: 150px;
  }

  .nameproduct{
    max-width: 190px;
    width: 190px;
    min-height: 90px;
  }

  h4{
    font-size:16px;
    text-overflow: ellipsis;
    max-width:60ch;
  }

  h5{
    text-align:left;
  }
  .container {
    padding: 20px 15px;
  }
  .add__btn {
    width: 100%;
    text-align: center;
    padding: 10px 10px;
    background: linear-gradient(180deg, #f3ba70, #B08653 );
    color: #fff;
    transition: 0.15s;
    font-size: 13px;
    font-weight: bold;
    border-radius: 10px;
  }
  .add__btn:hover {
    background: rgb(127, 0, 201);
    color: #fff;
  }
}

@media (min-width: 376px) and (max-width: 414px) {

  .card__wrapper {
    padding: 0px 0px;
  }

  .card {
    padding-top: 10px;
    width:100%;
    margin-bottom: 20px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) {

  .card__wrapper {
    padding: 5px 5px;
  }

  .card {
    padding-top: 10px;
    display: flex;
    border: 1px solid #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:180px;
    max-width: 70%;
    border-radius: 10px;
  }

  .card__wrapper h4, p{
    font-size:14px;
  }

}
