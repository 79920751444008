.vendedor{
  padding-bottom: 10px;
  max-width:95%;
  margin: 0 auto;
}

@media (max-width: 321px) { 
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 13px;
  }  
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    width:90%;
  }  
  .wrapper .checkout__btn {
    padding: 10px;
    width: 85%;
    font-size: 12px;
  }
  .vendedor p{
    font-size:12px;
    margin-bottom:5px;
  }  
}

@media (min-width: 361px) and (max-width: 376px) { 
  .vendedor{
    padding-bottom: 10px;
    max-width:90%;
    margin: 0 auto;
  }
  .vendedor p{
    font-size:14px;
    margin-bottom:5px;
  }
  .wrapper .items__container {
    height: 50vh;
    overflow-y: scroll;
  }
  .wrapper .checkout__btn {
    font-weight: bold;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content strong{
    font-size: 15px;
  }
}

@media (min-width: 376px) and (max-width: 414px) { 
  .vendedor{
    padding-bottom: 10px;
    max-width:90%;
    margin: 0 auto;
  }
  .vendedor p{
    font-size:14px;
    margin-bottom:5px;
  }
  .wrapper .items__container {
    height: 50vh;
    overflow-y: scroll;
  }
  .wrapper .checkout__btn {
    font-weight: bold;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content strong{
    font-size: 15px;
  }
}


@media (min-width: 1025px) and (max-width: 1439px) { 
  .vendedor{
    padding-bottom: 10px;
    max-width:95%;
    margin: 0 auto;
  }
  .vendedor p{
    font-size:14px;
    margin-bottom:5px;
  }
  .wrapper .items__container {
    height: 50vh;
    overflow-y: scroll;
  }
  .wrapper .checkout__btn {
    font-weight: bold;
    text-align: center;
    padding: 8px;
    font-size: 12px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content strong{
    font-size: 15px;
  }
}