.page-login{
  background-color: #f4f5f7;
}
.aside{
  color:#f1f1f1;
  background-color: rgb(39, 39, 39);
  min-height: 100vh;
}

#login-title{
  padding: 30% 10%;
  overflow: hidden;
  z-index: 12;
  position: relative;
}

.aside h1{
  font-size:46px;
  font-weight: bold;
}

.aside h1 .tituloultralist{
  font-size:46px;
}

.aside p{
  font-size:18px;
}

#login-title h3{
  margin-top: -20px;
  font-size: 32px;
}

#backgroundImg{
  position:absolute;
  z-index: 1;
  max-height: 30%;
  max-width: 20%;
  left:0;
  bottom:0;
}

.main{
  align-items: center;
  justify-content: center;
  margin: auto !important;
}

#hd2{
  position:absolute;
  right:0;
  top:0;
  max-width:20%;
  max-height:20%;
}
.main #logo{
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.main #logo img{
  width:120px;
  height:120px;
}

.linehome{
  display: flex;
  border-top: 2px solid #e2e2e2;
  width: 80%;
  margin:20px auto;
}

#botao{
  margin: 20px auto;
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}

.btnViewCatalog{
  height: 55px;
  font-weight: 700;
  font-size: 14px;
  background: linear-gradient(217deg, #F9C785, #B08653 );
  color: #fff;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  border-radius: 28px;
  transition: filter 0.2s;
}
.btnViewCatalog.outlined {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.btnViewCatalog:not(:disabled):hover {
  filter: brightness(0.9);
}

.btnViewCatalog:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

#formulariologin {
  align-items: center;
  margin: 20px auto;
  display: flex;
  flex-direction:column;
}

#input{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#input .iconlogin{
  font-size:18px;
  margin-right: 10px;
  color:#B08653
}

#homeinputmail {
  background-color: #fff; 
  min-width:300px;
  border:0px;
  padding: 10px;
  border-radius: 10px;
  font: 14px 'Poppins', sans-serif !important;
  color: #5e5e5e;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
} 

#homeinputmail:focus {
  outline:none;
  color:#5e5e5e;
  background-color: #fff;
}

#homeinputmail:active{
  background-color: #fff; 
}

#homeinputpass {
  background-color: #fff; 
  min-width:300px;
  border:0px;
  padding: 10px;
  border-radius: 10px;
  font: 14px 'Poppins', sans-serif !important;
  color: #5e5e5e;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
} 

#homeinputpass:focus {
  outline:none;
  color:#5e5e5e;
  background-color: #fff;
}

#homeinputpass:active{
  background-color: #fff; 
}

.erroLogin{
  text-align: center;
  color:red;
  font-size: 13px;
}


/* 

CSS PRA MEDIA QUERY

*/


@media (max-width: 321px) { 

  .aside{
    display:none;
  }

  .main{
    height: 100vh;
    padding-top:20%;
    overflow: scroll;
    padding-bottom: 10vh;
  }

  #formulariologin p {
    font-size:13px;
  }

  #homeinputmail {
    min-width:250px;  
  } 

  #homeinputpass {
    min-width: 250px;
  }

  .btnViewCatalog{
    height: 45px;
    font-size: 13px;
    padding: 0 20px;
  }
  .btnViewCatalog.outlined {
    background: #000;
    border: 1px solid #000;
    color: #fff;
  }
  
  .btnViewCatalog:not(:disabled):hover {
    filter: brightness(0.9);
  }
  
  .btnViewCatalog:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

}

@media (min-width: 321px) and (max-width: 376px) { 

  .aside{
    display:none;
  }

  .main{
    height: 100vh;
    padding-top:20%;
    overflow: scroll;
    padding-bottom: 10vh;
  }

  #formulariologin p {
    font-size:13px;
    text-align:center;
  }

  #homeinputmail {
    min-width:290px;
  } 

  #homeinputpass {
    min-width: 290px;
  } 

}



@media (min-width: 376px) and (max-width: 414px) { 

  .aside{
    display:none;
  }

  .main{
    height: 100vh;
    padding-top:20%;
    overflow: scroll;
    padding-bottom: 10vh;
  }

  #formulariologin p {
    font-size:13px;
    text-align:center;
  }

  #homeinputmail {
    min-width:320px;
  } 

  #homeinputpass {
    min-width: 320px;
  } 

}



@media (min-width: 415px) and (max-width: 769px) { 

  .aside{
    display:none;
  }

  .main{
    height: 100vh;
    padding-top:25%;
    overflow: scroll;
    padding-bottom: 10vh;
  }

  #formulariologin p {
    font-size:13px;
    text-align:center;
  }

  #homeinputmail {
    min-width:360px;
  } 

  #homeinputpass {
    min-width: 360px;
  } 

}

@media (min-width: 769px) and (max-width: 1025px) { 

  #login-title{
    padding: 10% 10%;
    overflow: hidden;
    z-index: 12;
    position: relative;
  }
  
  .aside h1{
    font-size:30px;
    font-weight: bold;
  }
  
  .aside h1 .tituloultralist{
    font-size:46px;
  }
  
  .aside p{
    font-size:15px;
  }
  
  #login-title h3{
    margin-top: -20px;
    font-size: 32px;
  }

  .main{
    height: 100vh;
    padding-top:15%;
  }

  #formulariologin p {
    font-size:16px;
    text-align:center;
  }

  #homeinputmail {
    min-width:360px;
  } 

  #homeinputpass {
    min-width: 360px;
  } 

}


@media (min-width: 1025px) and (max-width: 1439px) { 
  #login-title{
    padding: 20% 10%;
    overflow: hidden;
    z-index: 12;
    position: relative;
  }

  .aside h1{
    font-size: 36px;
  }

  .aside p{
    font-size:15px;
  }

}