.tabelaclientesanalise{
  font-size:10px;
}

.btnAprovar {
  background-color: rgb(153, 185, 98);
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  width: 100%;
  padding:5px;
}
.btnAprovar:hover {
  background-color: rgb(119, 155, 57);
  border-radius: 10px;
  border-width: 0px;
  color: #fff;
  width: 100%;
  padding:5px;
}

.inputfiltroclientes{
  background: #d3d3d3;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  justify-content: flex-start;
}

.inputfiltroclientes p{
  margin-bottom:0px;
  color:#fff;
  padding-top:2px;
  margin-right: 15px;
  margin-left: 10px;
}

#inputfiltroclientes{
  padding-top: 0px;
  border-radius: 5px;
  border-width: 0px;
  width: 100%;
  padding-left: 5px;
}

#inputfiltroclientes:focus{
  outline:2px dotted #B08653;
}


@media (max-width: 320px) { 

  #cardclienteanalise{
    background-color: #fff;
    border-radius:10px;
    font-size:12px;
    padding:10px;
    box-shadow: 1px 1px 4px #c2c2c2;
    width:200px;
    margin-bottom:10px;
    float: left;
  }

}

@media (min-width: 361px) and (max-width: 376px) { 

  #containerclienteanalise{
    padding-bottom: 30px;
    height:60%;
    overflow: scroll;
  }

  #cardclienteanalise{
    background-color: #fff;
    border-radius:10px;
    font-size:12px;
    padding:10px;
    box-shadow: 1px 1px 4px #c2c2c2;
    width:230px;
    margin-bottom:20px;
    float: left;
  }

}


@media (min-width: 376px) and (max-width: 414px) { 

  #containerclienteanalise{
    padding-bottom: 30px;
    height:65%;
    overflow: scroll;
  }

  #cardclienteanalise{
    background-color: #fff;
    border-radius:10px;
    font-size:12px;
    padding:10px;
    box-shadow: 1px 1px 4px #c2c2c2;
    width:265px;
    margin-bottom:20px;
    float: left;
  }

  .inputfiltroclientes{
    margin-bottom: 3px;
  }

  .tabelaclientesanalise{
    display: none;
  }

  .tabelaclientesanalisemobile{
    height:80vh
  }

  #boxcliente{
    padding: 10px;
    background-color: #e6e6e6;
    border-radius: 10px;
    margin-bottom:30px;
    font-size:11px;
    margin-left: 1px;
    margin-right:1px;
    box-shadow: 1px 1px 5px #c2c2c2;
  }

  #boxcliente span{
    font-weight: bold;
  } 

}

@media (min-width: 415px) { 
  .tabelaclientesanalisemobile{
    display: none;
  }
}


@media (min-width: 415px) and (max-width: 768px) { 

  #containerclienteanalise {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 90%;
    overflow:scroll;
  }

  #containerclienteanalise > #cardclienteanalise {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
    padding:10px;
    border-radius: 10px;
  }

}


@media (min-width: 769px) and (max-width: 1024px) { 

  #containerclienteanalise {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 90%;
    overflow:scroll;
  }

  #containerclienteanalise > #cardclienteanalise {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
    padding:10px;
    border-radius: 10px;
  }

}

@media (min-width: 1025px) and (max-width: 1439px) { 

  #containerclienteanalise {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 87%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #containerclienteanalise > #cardclienteanalise {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
    padding:10px;
    border-radius: 10px;
  }

}


@media (min-width: 1440px) and (max-width: 2560px) { 

  #containerclienteanalise {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    height: 87%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  #containerclienteanalise > #cardclienteanalise {
    box-shadow: 1px 1px 4px #c2c2c2;
    background-color:#fff;
    flex: 1 1 auto;
    font-size:13px;
    margin: 10px;
    width: 300px;
    height:fit-content;
    padding:10px;
    border-radius: 10px;
  }

}